import React, { useEffect,useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import { Container, Row,Label, Col,Modal,ModalHeader,ModalBody, Card, CardBody, TabContent,Alert, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from "classnames";
import EditProfileForm from "./edit";
import ProfileEdit from "./profileEdit";
import axios from "axios";
import CopyLink from "../../../components/CommonForBoth/TopbarDropdown/CopyLink";
import BACKEND_DOMAIN, { STORE_FRONT_DOMAIN } from "../../../service";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "../Utilities/canvasPreview"; // Ensure you have this utility function
import { useDebounceEffect } from "../Utilities/useDebounceEffect"; // Ensure you have this custom hook
import imageCompression from 'browser-image-compression';

const WEB_APP_URL = STORE_FRONT_DOMAIN;

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}


const ProfilePage = () => {
    const [userData, setUserData] = useState({});
    const authToken = useSelector((state) => state.Login.token);
    const [isEditing, setIsEditing] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [showProfileEdit, setShowProfileEdit] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [profileHovered, setProfileHovered] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successUpdate, setSuccessUpdate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState(null); // Track the file name

    // infile processing 
    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageType, setSelectedImageType] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [scale, setScale] = useState(1);


    const [selectedOption, setSelectedOption] = useState("");
    const [feeExists, setFeeExists] = useState(false); 
    const [feeMessage, setFeeMessage] = useState("");

// notifications
const [selectedNotificatiinOptions, setSelectedNotificatiinOptions] = useState("");
const [notificationsModalOpen, setNotificationModalOpen] = useState(false); 
const [emailNotification, setEmailNotification] = useState(false);
const [smsNotification, setSmsNotification] = useState(false);
const [whatsappNotification, setWhatsappNotification] = useState(false);
const [updateMessage, setUpdatedMessage] = useState("");
const [notifyMsg, setNotifyMessage] = useState("");

console.log('business userData ',userData);

const handleUpdateNotifications = ()=>{
   // console.log('button clicked');
    setNotificationModalOpen(true);
    }

// edit notifications
useEffect(() => {
    // Fetch existing fee settings
    const fetchNotifications = async () => {
        try {
            const response = await axios.get(
                `https://backoffice.route.money/api/v1/business/notification/settings/${userData.business}/`,
                {
                    headers: {
                        Authorization:`Bearer ${authToken}`,
                    },
                }
            );
            
            const notifications = response.data;

            setEmailNotification(notifications.email_notification)
            setSmsNotification(notifications.sms_notification)
            setWhatsappNotification(notifications.whatsapp_notification)
          
        } catch (error) {
            console.error("Error fetching fee settings:", error);
        }
    };

    fetchNotifications();
}, [authToken, userData]);

const handleCheckboxChange = (type) => {
    switch (type) {
        case 'email':
            setEmailNotification(!emailNotification);
            break;
        case 'sms':
            setSmsNotification(!smsNotification);
            break;
        case 'whatsapp':
            setWhatsappNotification(!whatsappNotification);
            break;
        default:
            break;
    }
};

const handleNotify = () => {
    setNotifyMessage("Click the Notifications settings button on the right..!")

    setTimeout(() => {
    setNotifyMessage("");
    }, 3000);
}
const handleSaveNotification = () => {
    setIsLoading(true);
    
    const updatedNotifications = {
        email_notification: emailNotification,
        sms_notification: smsNotification,
        whatsapp_notification: whatsappNotification
    };

 // Example: Axios request to save notifications (replace with your actual endpoint)
    axios.post(`https://backoffice.route.money/api/v1/business/notification/settings/${userData.business}/`, updatedNotifications)
        .then(response => {
            // Handle success
           // console.log('Notifications updated', response.data);
            setUpdatedMessage("Notifications options updated successfully!")

            setTimeout(() => {
            setUpdatedMessage("");
            }, 3000);
            setNotificationModalOpen(false);
        })
        .catch(error => {
            // Handle error
            console.error('Error updating notifications', error);
        })
        .finally(() => {
            setIsLoading(false);
        });
};


// edit fee

            useEffect(() => {
                // Fetch existing fee settings
                const fetchFeeSettings = async () => {
                    try {
                        const response = await axios.get(
                            `https://backoffice.route.money/api/v1/business/merchants/${userData.business}/fee-settings/`,
                            {
                                headers: {
                                  //  merchantId: userData.business,
                                    Authorization:`Bearer ${authToken}`,
                                },
                            }
                        );
                        
                        const feeOption = response.data.fee_sharing_option;
                        if (feeOption) {
                          console.log('current fee options ',feeOption);
                            setSelectedOption(feeOption);
                          
                            setFeeExists(true);
                        } else {
                            console.log('no fee option ');
                            setFeeExists(false);
                        }
                    } catch (error) {
                        console.error("Error fetching fee settings:", error);
                    }
                };

                fetchFeeSettings();
            }, [authToken, userData]);

                // Handle radio button change
                const handleRadioChange = (event) => {
                    const feeType = event.target.value;
                    setSelectedOption(feeType);

                    registerFee(feeType);
                    // if (feeExists) {
                    //     // Update the existing fee if it exists
                    //     editFee(feeType);
                    // } else {
                    //     // Register a new fee if it does not exist
                    //     registerFee(feeType);
                    // }
                };

                console.log('selected fee option ',feeExists)
                // Register a new fee
                const registerFee = async (feeType) => {
                    try {
                        const response = await axios.post(
                            'https://backoffice.route.money/api/v1/business/fee-settings/',
                            {
                                merchantId: userData.business,
                                fee_sharing_option: feeType,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${authToken}`,
                                    "Content-Type": "application/json",
                                },
                            }
                        );
                     //   console.log("Fee registered successfully:", response.data);
                        setFeeMessage("Fee registered successfully");
                        setFeeExists(true);  // Set the fee exists flag to true
                    } catch (error) {
                        console.error("Error registering the fee:", error);
                        setFeeMessage("Error registering the fee:");
                    }
                };

            // Update the existing fee
            const editFee = async (feeType) => {
                try {
                    const response = await axios.post(
                        `https://backoffice.route.money/api/v1/business/merchants/${userData.business}/fee-settings/`,
                        {
                            merchant: userData.business,
                            fee_sharing_option: feeType,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                                "Content-Type": "application/json",
                            },
                        }
                    );
                //    console.log("Fee updated successfully:", response.data);
                    setFeeMessage("Fee updated successfully");
                } catch (error) {
                    console.error("Error updating the fee:", error);
                    setFeeMessage("Error updating the fee");
                }
            };

// edit fee end

  

    const [updatedData, setUpdatedData] = useState({
        profile_image: null,
        cover_image: null,
    });

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const fileInputRef = useRef(null);

  // Reset the state to null for both fields
const resetUpdatedData = () => {
    setUpdatedData({
        profile_image: null,
        cover_image: null,
    });
};
    // Function to handle the click event on the pencil icon
    const handleProfileImageUpdate = () => {  
        setSelectedImageType("profile_image");
        fileInputRef.current.click();
        };

        const handleCoverImageUpdate = () => {

            setSelectedImageType('cover_image')
            fileInputRef.current.click();
            };



 // Handle the selected file
 let aspect;
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        //console.log('file ',file);

        // reset all image handlers
         // Reset states before handling new image
         if (file) {
            // Reset all image handlers
            setImgSrc(null); // Clear the current image source
            setCrop(null); // Reset the crop state if any
            resetUpdatedData(); // Clear other related data if necessary
    
            // Check if file has a name
            if (file.name) {
                setFileName(file.name); // Set the file name
            }
        } else {
            console.error("No file selected or file input is empty.");
            setErrorMessage("No file selected or file input is empty.");

            setTimeout(()=>{
                setErrorMessage("")
            },3000);
        }

       
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImgSrc(reader.result);
                setModalOpen(true); // Open the modal for cropping
                if (selectedImageType === "profile_image") {
                    aspect= 1;
                    setCrop({ aspect: 1 }); // 1:1 aspect for profile image
                } else {
                 aspect= 16/9;
                    setCrop({ aspect: 16 / 9 }); // 16:9 aspect for cover image
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale);
            }
        },
        100,
        [completedCrop, scale]
    );


   
    const handleSaveCrop = async () => {
        setIsLoading(true);
        const canvas = previewCanvasRef.current;
    
        canvas.toBlob(async (blob) => {
            if (blob) {
                try {
                    const compressedBlob = await imageCompression(blob, {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1200,
                        useWebWorker: true,
                    });
    
                    ///console.log("Compressed Blob:", compressedBlob);
    
                    // Add file name to metadata
                    compressedBlob.name = fileName; // Set the file name property
                    if (selectedImageType === "profile_image") {
                        setUpdatedData((prevState) => ({
                            ...prevState,
                            profile_image: compressedBlob,
                        }));
                    } else if (selectedImageType === "cover_image") {
                        setUpdatedData((prevState) => ({
                            ...prevState,
                            cover_image: compressedBlob,
                        }));
                    }
    
                    // Trigger save immediately after crop
                    await handleSave(compressedBlob);
    
                    // Close modal and reset crop state
                    setModalOpen(false);
                   
    
                } catch (error) {
                    console.error("Error during image compression:", error);
                }
            } else {
                console.error("Canvas to blob failed!");
            }
        });
    };
    

    const handleSave = async (imageBlob) => {
        try {
            setIsLoading(true);
            //console.log('update data ',imageBlob);
            const formData = new FormData();

            if (selectedImageType === "profile_image") {
                // formData.append("profile_image", updatedData.profile_image);
                formData.append("profile_image", imageBlob);
            }
            if (selectedImageType === "cover_image") {
                formData.append("cover_image", imageBlob);
            }

            await axios.patch(`${BACKEND_DOMAIN}/business/merchant/image/update`, formData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setSuccessUpdate('Image updated successfully!');

            setTimeout(() => {
            setSuccessUpdate("");
            }, 3000);

            fetchUserData(); // Fetch updated user data after saving
        } catch (error) {
            console.error("Error saving images:", error);
        } finally{
            setCompletedCrop(null);
            setIsLoading(false);
        }
    };
    

    const fetchUserData = async () => {
        try {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/detail/`;
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setUserData(response.data);

         //   console.log('business info ',response.data);
        } catch (error) {
            console.error("Error fetching user details:", error.message);
        }
    };


    useEffect(() => {
        fetchUserData();
    }, [authToken]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setActiveTab(tab);
            }
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleMouseEntry = () => {
        setProfileHovered(true);
    };

    const handleMouseOut = () =>{
        setProfileHovered(false);
    }

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setShowProfileEdit(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="justify-content-center mb-5 mb-sm-0">
                        <Col md="12" className="h-100">
                            <Card>
                                <CardBody>
                                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                                {successUpdate && <Alert color="success">{successUpdate}</Alert>}
                                    <div className="d-flex flex-column flex-md-row">
                                        <div sm={12} md={3} className="text-center me-md-3 position-relative" 
                                                  onMouseEnter={handleMouseEnter}
                                                 onMouseLeave={handleMouseLeave}>
                                            <div className="mb-3 position-relative">
                                                <img
                                                    src={userData.cover_image}
                                                    alt="Cover Image"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: 8,
                                                        height: "150px",
                                                        width: "100%",
                                                    }}
                                                />

                                                <input
                                                        type="file"
                                                        id="cover_image"
                                                        name="cover_image"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange} // Handle file change event here
                                                    />

                                                 <div
                                                    className="position-absolute bottom-0 end-0 me-1 mb-2"
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                                                        borderRadius: "3%",
                                                        padding: "3px",
                                                        opacity: isHovered ? 1 : 0,
                                                         transition: "opacity 0.2s",
                                                        
                                                    }}
                                                    onClick={handleCoverImageUpdate}
                                                >
                                                <i className="mdi mdi-pencil font-size-18"></i>
                                                </div>
                                            </div>

                                            {/* Profile Image with Edit Icon */}
                                            <div className="position-relative" style={{ marginTop: "-50px",width:"130px" }}
                                                onMouseEnter={handleMouseEntry}
                                                onMouseLeave={handleMouseOut}
                                                >

                                                <img
                                                    src={userData.profile_image}
                                                    alt="Profile"
                                                    className="avatar-lg"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: "50%",
                                                        border: "3px solid white",
                                                        height: "100px",
                                                        width: "100px",
                                                    }}
                                                />

                                                     <input
                                                        type="file"
                                                        id="profile_image"
                                                        name="profile_image"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange} // Handle file change event here
                                                    />
                                                <div
                                                    className="position-absolute bottom-0 end-0 me-3 mb-2"
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                                                        borderRadius: "5%",
                                                        padding: "3px",
                                                        opacity: profileHovered ? 1 : 0,
                                                        transition: "opacity 0.2s",
                                                    }}
                                                    onClick={handleProfileImageUpdate}
                                                >
                                                <i className="mdi mdi-pencil font-size-18"></i>
                                                </div>
                                            </div>

                                            <h5 className="mt-3">
                                                <b>{userData.name && capitalizeFirstLetter(userData.name)}</b>
                                            </h5>

                                            <CopyLink url={WEB_APP_URL} slugName={userData?.slug} />

                                            {isEditing && <ProfileEdit userData={userData} />}
                                        </div>
                                        <div
                                            sm={12}
                                            md={9}
                                            className="mt-xs-3 d-flex flex-column"
                                            style={{ width: "100%" }}
                                        >
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 1 })}
                                                        onClick={() => {
                                                            toggleTab(1);
                                                        }}
                                                    >
                                                        Business Info
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 2 })}
                                                        onClick={() => {
                                                            toggleTab(2);
                                                        }}
                                                    >
                                                        Logistics
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 3 })}
                                                        onClick={() => {
                                                            toggleTab(3);
                                                        }}
                                                    >
                                                        Fees
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 4 })}
                                                        onClick={() => {
                                                            toggleTab(4);
                                                        }}
                                                    >
                                                        Notifications Settings
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={1}>
                                                    <Row>
                                                        <Col
                                                            sm="12"
                                                            className="d-flex flex-column align-items-start p-4"
                                                        >
                                                            <h6 style={{ fontSize: "13px" }}>Business Type</h6>
                                                            <p>
                                                                <b>{userData.type}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Email</h6>
                                                            <p>
                                                                <b>{userData.email}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>KRA PIN</h6>
                                                            <p>
                                                                <b>{userData.kra_pin}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>
                                                                WhatsApp Business Phone No
                                                            </h6>
                                                            <p>
                                                                <b>{userData.phone_number}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>
                                                                Contact Person Phone No
                                                            </h6>
                                                            <p>
                                                                <b>{userData.alt_phone_number}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Contact Person</h6>
                                                            <p>
                                                                <b>{userData.contact_person}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Location</h6>
                                                            <p>
                                                                <b>{userData.location}</b>
                                                            </p>
                                                        </Col>

                                                        <div xs={12} lg={12} className="d-flex justify-content-end">
                                                            <Button
                                                                style={{
                                                                    backgroundColor: "#5664d2",
                                                                    alignSelf: "end",
                                                                }}
                                                                onClick={handleEditClick}
                                                            >
                                                                Update Profile Details
                                                            </Button>
                                                        </div>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <Row>
                                                        <Col sm="12" className="p-4">
                                                            <h6 style={{ fontSize: "13px" }}>VAT:</h6>
                                                            <p>
                                                                <b>16%</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>
                                                                Delivery Fees Within Nairobi:
                                                            </h6>
                                                            <p>
                                                                <b>KES 700</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Opening Hours:</h6>
                                                            <p>
                                                                <b>Monday - Friday: 08:00AM</b>
                                                            </p>
                                                            <p>
                                                                <b>Saturday & Sunday: 08:00AM</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Closing Hours:</h6>
                                                            <p>
                                                                <b>Monday - Friday: 9:00PM</b>
                                                            </p>
                                                            <p>
                                                                <b>Saturday & Sunday: 8:00PM</b>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                
                                                <TabPane tabId={3}>
                                                    <Row>
                                                        <Col sm="12" className="p-4">
                                                            
                                                       
                                                        {selectedOption ? (
                                                            // If fee exists, green and bold text
                                                            <h4 className="text-center text-success font-weight-bold mb-4" style={{ fontSize: "16px" }}>
                                                                Updated option: {selectedOption}
                                                            </h4>
                                                        ) : (
                                                            // If no fee exists, red text
                                                            <h4 className="text-center text-danger mb-4" style={{ fontSize: "16px" }}>
                                                                Set Transaction Fees Share
                                                            </h4>
                                                        )}
                                                         
                                                            <p>
                                                                <b>  <Label htmlFor="selectProducts">Customer to Incur entire cost</Label></b>
                                                                <div>
                                                                <input
                                                                type="radio"
                                                                id="consumer"
                                                                name="transactionFee"
                                                                value="consumer"
                                                                checked={selectedOption === "consumer"}
                                                                onChange={handleRadioChange}
                                                            />
                                                        
                                                            </div>

                                                            </p>
                                                         
                                                            <p>
                                                            <b>  <Label htmlFor="selectProducts">Equal Division between Customer and Merchant</Label></b>
                                                                <div>
                                                                <input
                                                                type="radio"
                                                                id="split"
                                                                name="transactionFee"
                                                                value="split"
                                                                checked={selectedOption === "split"}
                                                                onChange={handleRadioChange}
                                                                />
                                                        
                                                            </div>
                                                            </p>

                                                            <p>
                                                            <b>  <Label htmlFor="selectProducts">Merchant to Incur Whole Cost</Label></b>
                                                                        
                                                                <div>
                                                                <input
                                                                type="radio"
                                                                id="merchant"
                                                                name="transactionFee"
                                                                value="merchant"
                                                                checked={selectedOption === "merchant"}
                                                                onChange={handleRadioChange}
                                                                />
                                                        
                                                            </div>
                                                            </p>
                                                           
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <Row>
                                                        <Col sm="12" className="p-4">
                                                        {updateMessage && <Alert color="success">{updateMessage}</Alert>}
                                                        {notifyMsg && <Alert color="warning">{notifyMsg}</Alert>}
                                            <h4 className="text-center text-success mb-4" style={{ fontSize: "16px" }}>
                                                          Notifications Options   
                                                            </h4>

                                                  <div className="notification-options">
                                                <div className="notification-item">
                                                    <input
                                                        type="checkbox"
                                                        checked={emailNotification}
                                                        onChange={() => handleNotify()}
                                                    />
                                                    <label>Email Notification</label>
                                                </div>
                                                <div className="notification-item">
                                                    <input
                                                        type="checkbox"
                                                        checked={smsNotification}
                                                        onChange={() => handleNotify()}
                                                    />
                                                    <label>SMS Notification</label>
                                                </div>
                                                <div className="notification-item">
                                                    <input
                                                        type="checkbox"
                                                        checked={whatsappNotification}
                                                        onChange={() => handleNotify()}
                                                    />
                                                    <label>WhatsApp Notification</label>
                                                </div>
                                            </div>
                                                        
                                       <div xs={12} lg={12} className="d-flex justify-content-end">
                                                <Button
                                                    style={{
                                                        backgroundColor: "#5664d2",
                                                        alignSelf: "end",
                                                    }}
                                                    onClick={handleUpdateNotifications}
                                                >
                                                    Notifications Settings
                                                </Button>
                                            </div>
                                                           
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
  
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            
            </div>

                        {/* Crop Modal */}
                        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                            Crop {selectedImageType === "profile_image" ? "Profile" : "Cover"} Image
                        </ModalHeader>
                        <ModalBody>
                            {imgSrc && (
                                <Row>
                                    <Col md="6">
                                        <ReactCrop
                                            crop={crop}
                                            onChange={(newCrop) => setCrop(newCrop)}
                                            onComplete={(newCrop) => setCompletedCrop(newCrop)}
                                            aspect={aspect}
                                        >
                                            <img
                                                ref={imgRef}
                                                alt="Crop me"
                                                src={imgSrc}
                                                onLoad={() => {
                                                    setCrop({ unit: "%", width: 50, aspect });
                                                }}
                                                style={{ maxWidth: "100%", maxHeight: "400px" }}
                                            />
                                        </ReactCrop>
                                    </Col>
                                    <Col md="6">
                                        {completedCrop && (
                                            <canvas
                                                ref={previewCanvasRef}
                                                style={{
                                                    border: "1px solid black",
                                                    width: "100%",
                                                    maxHeight: "400px",
                                                }}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </ModalBody>
                        <div className="modal-footer">
                            <Button color="secondary" onClick={() => setModalOpen(false)}>
                                Cancel
                            </Button>
                            <Button color={isLoading ? `secondary`:`primary`} onClick={handleSaveCrop} disabled={isLoading}>
                            {isLoading ? 'Saving ...' : 'Save'}
                            </Button>
                        </div>
                    </Modal>

                                            {/* notification settings */}
                                            <Modal isOpen={notificationsModalOpen} toggle={() => setNotificationModalOpen(!notificationsModalOpen)}>
    <ModalHeader toggle={() => setNotificationModalOpen(!notificationsModalOpen)}>
        Choose Notifications Options
    </ModalHeader>
    <ModalBody>
        <div className="notification-options">
            <div className="notification-item">
                <input
                    type="checkbox"
                    checked={emailNotification}
                    onChange={() => handleCheckboxChange('email')}
                />
                <label>Email Notification</label>
            </div>
            <div className="notification-item">
                <input
                    type="checkbox"
                    checked={smsNotification}
                    onChange={() => handleCheckboxChange('sms')}
                />
                <label>SMS Notification</label>
            </div>
            <div className="notification-item">
                <input
                    type="checkbox"
                    checked={whatsappNotification}
                    onChange={() => handleCheckboxChange('whatsapp')}
                />
                <label>WhatsApp Notification</label>
            </div>
        </div>
    </ModalBody>
    <div className="modal-footer">
        <Button color="secondary" onClick={() => setNotificationModalOpen(false)}>
            Cancel
        </Button>
        <Button
            color={isLoading ? 'secondary' : 'primary'}
            onClick={handleSaveNotification}
            disabled={isLoading}
        >
            {isLoading ? 'Updating ...' : 'Update'}
        </Button>
    </div>
</Modal>

        </React.Fragment>
    );
};

export default ProfilePage;
