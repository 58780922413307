import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Modal, ModalHeader,Spinner, ModalBody, ModalFooter, Button,Form, FormGroup, Label, Input } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import LatestTransactions from "./LatestTransactions";
import RecentOrders from "./RecentOrders";
import {formatNumber} from '../FigureFormatter';

import {
    fetchProductsOrdersAndBranches,
    fetchProductOffersData,
    fetchCashbackOffersData,
    fetchWalletBalanceData
} from "./data_fetching/dashboardData";

const Dashboard = ({ isBusinessPartner }) => {
    const authToken = useSelector((state) => state.Login.token);

    const [localIsBusinessPartner, setLocalIsBusinessPartner] = useState(isBusinessPartner);

    const [productsCount, setProductsCount] = useState(null);
    const [branchesCount, setBranchesCount] = useState(0);
    const [ordersCount, setOrdersCount] = useState(null);
    const [productOffers, setProductOffers] = useState(null);
    const [cashbackOffers, setCashbackOffers] = useState(0);
    const [walletBalance, setWalletBalance] = useState(null);
    const [modal, setModal] = useState(false);
    const [pinCode, setPinCode] = useState('');
    const [loading, setLoading]= useState(true);

    const toggleDeleteModal = () => setModal(!modal);

    const breadcrumbItems = [
        { title: "dashboard", link: "#"},
        { title: "Dashboard", link: "#" },
    ];


    // create pin code
    
    

    const handleChange = (e) => {
        const { value } = e.target;
        // Ensure the value is a four-digit number
        if (/^\d{0,4}$/.test(value)) {
            setPinCode(value);
        }
    };
    const pinurl = `https://backoffice.route.money/api/v1/users/pin`;
   
    const handleSubmit = async(e) => {
        e.preventDefault();
        if (pinCode.length === 4) {
          //  confirmDelete(pinCode);
         alert(pinCode);
         try{
            const response = await fetch(pinurl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
                body:{
                    pin: pinCode,
                },
            });

            
            if (!response.ok) {
                throw new Error("Process failed, please try again");
            }
            if(response.ok){
                alert("pin code created!!")
            }
        } catch (error) {
           alert(error.message);
        }
        } else {
            alert('Please enter a 4-digit PIN code.');
        }
    };

    // end enter pincode
    useEffect(() => {
        const fetchIsPartner = async () => {
            try {
                const localData = localStorage.getItem("authUser");
                if (localData) {
                    const info = JSON.parse(localData);
                    setLocalIsBusinessPartner(info.is_business_partner);
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchIsPartner();
    }, []);

    // const setDashboardInfo = async (token) => {
    //     try {
    //         const [
    //             dashboardData,
    //             productOffersCount,
    //             cashbackOffersCount,
    //             walletBalanceData
    //         ] = await Promise.all([
    //             fetchProductsOrdersAndBranches(token),
    //             fetchProductOffersData(token),
    //             fetchCashbackOffersData(token),
    //             fetchWalletBalanceData(token)
    //         ]);

    //         setProductsCount(dashboardData.cards_data.products_count);
    //         setBranchesCount(dashboardData.cards_data.branches_count);
    //         setOrdersCount(dashboardData.cards_data.orders_count);

    //         setProductOffers(productOffersCount);
    //         setCashbackOffers(cashbackOffersCount);
    //         setWalletBalance(walletBalanceData.ledger_balance); // Ensure correct access to ledger_balance

    //         setLoading(false);
    //     } catch (e) {
    //         console.error("Failed to fetch dashboard data ->", e);
    //     }
    // };

    const setDashboardInfo = async (token) => {
        try {
       
            const [merchantCardData] = await Promise.all([fetchProductsOrdersAndBranches(token)]);

            // Safely access `cards_data` and its properties
            if (merchantCardData && merchantCardData.cards_data) {
                const { products_count, branches_count, orders_count, wallet_balance } = merchantCardData.cards_data;  
                // Update individual states
                setProductsCount(products_count || 0); // Default to 0 if undefined
                setBranchesCount(branches_count || 0);
                setOrdersCount(orders_count || 0);
               setProductOffers(0);
                setCashbackOffers(0);
                setWalletBalance(wallet_balance?.available_balance || 0);

                // loading false
                setLoading(false);
            } else {
                console.error("Invalid merchantCardData structure:", merchantCardData);
            }            } catch (error) {
            console.error("Error fetching dashboard data:", error);
        }
    };


    useEffect(() => {
        setDashboardInfo(authToken);
    }, [authToken]);

    const businessPartnerReports = [
        {
            icon: "ri-currency-line",
            title: "Wallet Balance",
            value: loading ? (
                <div>
                    <Spinner size="sm" color="white" />
                </div>
            ) : walletBalance > 0 ? (
                `KES ${formatNumber(walletBalance)}`
            ) : (
                "KES 0.00"
            ),
            rate: "Create pin",
            desc: "From previous month",
            routeTo: "#",
        },        
        {
            icon: "ri-shopping-bag-2-line",
            title: "Number of Products", 
            value: loading ? 
                <div>
                <Spinner size="sm" color="white" />
            </div>
            : productsCount,

            // value: productsCount > 0 
            // ? productsCount
            // : productsCount === 0 
            //     ? "0" 
            //     : <div><Spinner size="sm" color="white" /></div>,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/products",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            // value: ordersCount > 0 
            // ? ordersCount
            // : ordersCount === 0 || ordersCount === null
            //     ? "0" 
            //     : <div><Spinner size="sm" color="white" /></div>,


            value: loading ? 
            <div>
            <Spinner size="sm" color="white" />
        </div>
        : ordersCount,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/order-history",
        },

        {
            icon: "ri-coupon-line",
            title: "Product Offers",
            // value: productOffers > 0 
            // ? productOffers
            // : productOffers === 0 || productOffers === null
            //     ? "0" 
            //     : <div><Spinner size="sm" color="white" /></div>,

            value: loading ? 
            <div>
            <Spinner size="sm" color="white" />
        </div>
        : productOffers || 0,

            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/branch/product-offers",
        },
        {
            icon: "ri-refund-2-line",
            title: "Cashback Offers",
            // value: cashbackOffers || 0,
            value: loading ? 
            <div>
            <Spinner size="sm" color="white" />
        </div>
        : cashbackOffers || 0,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/cashback-offers",
        },
    ];

    const superUserReports = [
        {
            icon: "ri-currency-line",
            title: "Master Wallet Bal",
            value: "KES 0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/revenue-report",
        },
        {
            icon: "ri-stack-line",
            title: "Gross Merchandise Value (GMV)",
            value: "KES 0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/collection-transactions",
        },
        {
            icon: "ri-wallet-2-line",
            title: "Monthly Revenue",
            value: "KES 0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/revenue-report",
        },
        {
            icon: "ri-group-line",
            title: "Number of Users",
            value: 1,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
        {
            icon: "ri-medium-line",
            title: "Average Revenue Per User (ARPU)",
            value: "KES 0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchants",
        },
        {
            icon: "ri-group-2-line",
            title: "Customer Churn Rate",
            value: "0.0 %",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                   
                        <>
                            <Breadcrumbs title="Manager Dashboard" breadcrumbItems={breadcrumbItems} />

                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        <MiniWidgets reports={businessPartnerReports} />
                                    </Row>
                                    <RevenueAnalytics />
                                </Col>
                                <Col xl={4}>
                                    <SalesAnalytics isAdmin={false} />
                                    <EarningReports />
                                </Col>
                            </Row>
                            <Row>
                                <LatestTransactions />
                                <RecentOrders isAdmin={false} />
                            </Row>
                        </>
                    

          <Modal isOpen={modal} toggle={toggleDeleteModal}>
            <ModalHeader> Create Pincode</ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    <FormGroup>
                        <Label for="pinCode">Enter 4-digit PIN code to confirm:</Label>
                        <Input
                            type="text"
                            name="pinCode"
                            id="pinCode"
                            value={pinCode}
                            onChange={handleChange}
                            maxLength="4"
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="warning" type="submit">
                        save
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>

                </Container>
            </div>
        </>
    );
};

export default Dashboard;
