import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col,Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import LatestTransactions from "./LatestTransactions";
import RecentOrders from "./RecentOrders";
import {formatNumber} from './FigureFormatter';
import {
    fetchProductsOrdersAndBranches,
    fetchProductOffersData,
    fetchCashbackOffersData,
    fetchWalletBalanceData,
    fetchChurnRate,
    fetchTotalARPU,
    fetchTotalMerchants,
    fetchTotalUsers,
    fetchMonthlyRevenue,
    fetchUpdatedgvm
} from "./data_fetching/dashboardData";

const Dashboard = ({ isBusinessPartner }) => {
    const authToken = useSelector((state) => state.Login.token);

    const [localIsBusinessPartner, setLocalIsBusinessPartner] = useState(isBusinessPartner);

    const [productsCount, setProductsCount] = useState(0);
    const [branchesCount, setBranchesCount] = useState(0);
    const [ordersCount, setOrdersCount] = useState(0);
    const [productOffers, setProductOffers] = useState(null);
    const [cashbackOffers, setCashbackOffers] = useState(0);
    const [walletBalance, setWalletBalance] = useState(null);

    // admin car items
    const [churnRate, setChurnRate] = useState(0);
    const [arpu, setArpu]= useState(0);
    const [allMerchants, setAllMerchants] = useState(0);
    // const [totalMerchants, setTotalMerchants] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [monthlyRevenue, setMonthlyRevenue] = useState(null);
    const [allcarddata, setAllCardData] = useState([]);
    const [gvm, setGVM]= useState(0);
    const [adminWallet, setAdminWallet]= useState(null);
    const [allOrders, setAllOrders]=useState(null);
    const  [allBranches, setAllBranches]=useState(null);
    const [loading, setLoading]=useState(true);
    const [businessLoading, setBusinessLoading]=useState(true);
  

    const breadcrumbItems = [
        { title: "Route Money", link: "/" },
        { title: "Dashboard", link: "#" },
    ];

    useEffect(() => {
        const fetchIsPartner = async () => {
            try {
                const localData = localStorage.getItem("authUser");
                if (localData) {
                    const info = JSON.parse(localData);
                    setLocalIsBusinessPartner(info.is_business_partner);
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchIsPartner();
    }, []);

    // useEffect(() => {
    //     const setDashboardInfo = async (token) => {
    //         try {
    //             const [
    //                 dashboardData,
    //                 productOffersCount,
    //                 cashbackOffersCount,
    //                 walletBalanceData,
    //             ] = await Promise.all([
    //                 fetchProductsOrdersAndBranches(token),
    //                 fetchProductOffersData(token),
    //                 fetchCashbackOffersData(token),
    //                 fetchWalletBalanceData(token),
    //                 fetchTotalARPU(token),
    //                 fetchTotalMerchants(token),
    //                 fetchTotalUsers(token),
    //                 fetchMonthlyRevenue(token),
    //                 fetchChurnRate(token),
    //             ]);

    //             setProductsCount(dashboardData.cards_data.products_count);
    //             setBranchesCount(dashboardData.cards_data.branches_count);
    //             setOrdersCount(dashboardData.cards_data.orders_count);
    //             setProductOffers(productOffersCount);
    //             setCashbackOffers(cashbackOffersCount);
    //             setWalletBalance(walletBalanceData.ledger_balance);

    //             setBusinessLoading(false);
       
    //         } catch (error) {
    //             console.error("Error fetching dashboard data:", error);
    //         }
    //     };

  
    //     console.log('products ',productsCount);

    //     // const setSuperDashboardInfo = async (token) => {
    //     //     try {
    //     //         const [
            
    //     //             gvms
    //     //         ] = await Promise.all([
                 
    //     //             fetchUpdatedgvm(token)
    //     //         ]);
    
    //     //       // console.log("Fetched GVM:", gvms);

    //     //             // Set the GVM data directly from `gvms` without using `allcarddata`
    //     //             setChurnRate(gvms.churn_rate);
    //     //             setArpu(gvms.arpu);
    //     //             setAllMerchants(gvms.total_merchants);
    //     //             setTotalUsers(gvms.total_users);
    //     //             setMonthlyRevenue(gvms.monthly_revenue);
    //     //             setGVM(gvms.gmv);
    //     //             setAdminWallet(gvms.wallet_balance.available_balance);
    //     //             setAllBranches(gvms.branches_count);
    //     //             setAllOrders(gvms.orders_count);
    
    //     //         // Set other data
              
    //     //     } catch (error) {
    //     //         console.error("Error fetching dashboard data:", error);
    //     //     }
    //     // };

    //     const setSuperDashboardInfo = async (token) => {
    //         try {
    //             const [gvms] = await Promise.all([fetchUpdatedgvm(token)]);
        
    //             // Set all data
    //             setChurnRate(gvms.churn_rate);
    //             setArpu(gvms.arpu);
    //             setAllMerchants(gvms.total_merchants);
    //             setTotalUsers(gvms.total_users);
    //             setMonthlyRevenue(gvms.monthly_revenue);
    //             setGVM(gvms.gmv);
    //             setAdminWallet(gvms.wallet_balance.available_balance);
    //             setAllBranches(gvms.branches_count);
    //             setAllOrders(gvms.orders_count);
        
    //             // Set loading to false
    //             setLoading(false);
    //         } catch (error) {
    //             console.error("Error fetching dashboard data:", error);
    //             setLoading(false); // Also stop loading in case of an error
    //         }
    //     };
    
    //     if (authToken) {
    //         setDashboardInfo(authToken);
    //         setSuperDashboardInfo(authToken);
    //     }
    // }, [authToken]);
    
    useEffect(() => {
        const setDashboardInfo = async (token) => {
            try {
           
                const [merchantCardData] = await Promise.all([fetchProductsOrdersAndBranches(token)]);

                // Safely access `cards_data` and its properties
                if (merchantCardData && merchantCardData.cards_data) {
                    const { products_count, branches_count, orders_count, wallet_balance } = merchantCardData.cards_data;  
                    // Update individual states
                    setProductsCount(products_count || 0); // Default to 0 if undefined
                    setBranchesCount(branches_count || 0);
                    setOrdersCount(orders_count || 0);
                   setProductOffers(0);
                    setCashbackOffers(0);
                    setWalletBalance(wallet_balance?.available_balance || 0);

                    // loading false
                    setBusinessLoading(false);
                } else {
                    console.error("Invalid merchantCardData structure:", merchantCardData);
                }            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            }
        };

        // const setSuperDashboardInfo = async (token) => {
        //     try {
        //         const [
            
        //             gvms
        //         ] = await Promise.all([
                 
        //             fetchUpdatedgvm(token)
        //         ]);
    
        //       // console.log("Fetched GVM:", gvms);

        //             // Set the GVM data directly from `gvms` without using `allcarddata`
        //             setChurnRate(gvms.churn_rate);
        //             setArpu(gvms.arpu);
        //             setAllMerchants(gvms.total_merchants);
        //             setTotalUsers(gvms.total_users);
        //             setMonthlyRevenue(gvms.monthly_revenue);
        //             setGVM(gvms.gmv);
        //             setAdminWallet(gvms.wallet_balance.available_balance);
        //             setAllBranches(gvms.branches_count);
        //             setAllOrders(gvms.orders_count);
    
        //         // Set other data
              
        //     } catch (error) {
        //         console.error("Error fetching dashboard data:", error);
        //     }
        // };

        const setSuperDashboardInfo = async (token) => {
            try {
                const [gvms] = await Promise.all([fetchUpdatedgvm(token)]);
        
                // Set all data
                setChurnRate(gvms.churn_rate);
                setArpu(gvms.arpu);
                setAllMerchants(gvms.total_merchants);
                setTotalUsers(gvms.total_users);
                setMonthlyRevenue(gvms.monthly_revenue);
                setGVM(gvms.gmv);
                setAdminWallet(gvms.wallet_balance.available_balance);
                setAllBranches(gvms.branches_count);
                setAllOrders(gvms.orders_count);
        
                // Set loading to false
                setLoading(false);
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
                setLoading(false); // Also stop loading in case of an error
            }
        };
    
        if (authToken) {
            setDashboardInfo(authToken);
            setSuperDashboardInfo(authToken);
        }
    }, [authToken]);
    
    

 
    const businessPartnerReports = [
        {
            icon: "ri-currency-line",
            title: "Wallet Balance",
         
            // value: walletBalance > 0 
            //     ? `KES ${formatNumber(walletBalance)}`
            //     : walletBalance === 0 || walletBalance === null
            //         ? "KES 0.00" 
            //         : <div><Spinner size="sm" color="white" /></div>,

            value: businessLoading ? (
                <Spinner size="sm" color="white" />
            ) : walletBalance > 0 ? (
                `KES ${formatNumber(walletBalance)}`
            ) : (
                "KES 0.00"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "#",
        },
        {
            icon: "ri-shopping-bag-2-line",
            title: "Number of Products",
            // value: productsCount ?
            // `${productsCount}`: <div><Spinner size="sm" color="white" /></div>
            // ,

            value: businessLoading ? (
                <Spinner size="sm" color="white" />
            ) : productsCount > 0 ? (
                productsCount
            ) : (
              "0"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/products",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            // value: ordersCount ? `${ordersCount}`:<div><Spinner size="sm" color="white" /></div>,
            value: businessLoading ? (
                <Spinner size="sm" color="white" />
            ) : ordersCount > 0 ? (
                ordersCount
            ) : (
              "0"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/order-history",
        },
        {
            icon: "ri-store-line",
            title: "Your Branches",
            // value: branchesCount ?`${branchesCount}`:<div><Spinner size="sm" color="white" /></div>,
            value: businessLoading ? (
                <Spinner size="sm" color="white" />
            ) : branchesCount > 0 ? (
                branchesCount
            ) : (
              "0"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/branch/list",
        },
        {
            icon: "ri-coupon-line",
            title: "Product Offers",
            // value: productOffers ? `${productOffers}`: 0,
            value: businessLoading ? (
                <Spinner size="sm" color="white" />
            ) : productOffers > 0 ? (
                productOffers
            ) : (
              "0"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/product-offers",
        },
        {
            icon: "ri-refund-2-line",
            title: "Cashback Offers",
            // value: cashbackOffers ?`${cashbackOffers}`: 0,
            value: businessLoading ? (
                <Spinner size="sm" color="white" />
            ) : cashbackOffers > 0 ? (
                cashbackOffers
            ) : (
              "0"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/cashback-offers",
        },
    ];

  
    const superUserReports = [

        // here
        {
            icon: "ri-currency-line",
            title: "Master Wallet Bal",
            value: loading ? (
                <Spinner size="sm" color="white" />
            ) : walletBalance > 0 ? (
                `KES ${formatNumber(walletBalance)}`
            ) : (
                "KES 0.00"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "#",
        },
 

        // to here
        {
            icon: "ri-stack-line",
            title: "Gross Merchandise Value (GMV)",
            value: loading ? (
                <Spinner size="sm" color="white" />
            ) : gvm > 0 ? (
                `KES ${formatNumber(gvm)}`
            ) : (
                "KES 0.00"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/collection-transactions",
        }
        
         ,
        {
            icon: "ri-wallet-2-line",
            title: "Monthly Revenue",
            value: loading ? (
                <Spinner size="sm" color="white" />
            ) : monthlyRevenue > 0 ? (
                `KES ${formatNumber(monthlyRevenue)}`
            ) : (
                "KES 0.00"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/revenue-report",
        },
        {
            icon: "ri-group-line",
            title: "Number of Users",  
            value: loading ?(
                <Spinner size="sm" color="white" />
            )
            : totalUsers || 0,
          
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
        {
            icon: "ri-medium-line",
            title: "Average Revenue Per User (ARPU)",
            value: loading ?(
                <Spinner size="sm" color="white" />
            )
            : arpu > 0 ? (
                `KES ${formatNumber(arpu)}`
            ) : (
                "KES 0.00"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchants",
        },
        {
            icon: "ri-group-2-line",
            title: "Customer Churn Rate",
            value: loading ? (
                <Spinner size="sm" color="white" />
            ) : churnRate > 0 ? (
                churnRate
            ) : (
              "0"
            ),

            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
        {
            icon: "ri-group-2-line",
            title: "Total Merchants",
            value: loading ? (
                <Spinner size="sm" color="white" />
            ) : allMerchants > 0 ? (
                allMerchants
            ) : (
              "0"
            ),

            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
        {
            icon: "ri-group-2-line",
            title: "Total Branches",
            value: loading ? (
                <Spinner size="sm" color="white" />
            ) : allBranches > 0 ? (
                allBranches
            ) : (
              "0"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
        {
            icon: "ri-group-2-line",
            title: "Total Orders",
            value: loading ? (
                <Spinner size="sm" color="white" />
            ) : allOrders > 0 ? (
                allOrders
            ) : (
              "0"
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {!localIsBusinessPartner && (
                        <>
                            <Breadcrumbs title="Admin Dashboard" breadcrumbItems={breadcrumbItems} />

                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        <MiniWidgets reports={superUserReports} />
                                    </Row>
                                    <RevenueAnalytics />
                                </Col>
                                <Col xl={4}>
                                    <SalesAnalytics isAdmin={true} />
                                    <EarningReports />
                                </Col>
                            </Row>
                            <Row>
                                <LatestTransactions />
                                <RecentOrders isAdmin={true} />
                            </Row>
                        </>
                    )}
                    {localIsBusinessPartner && (
                        <>
                            <Breadcrumbs title="Merchant Dashboard" breadcrumbItems={breadcrumbItems} />

                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        <MiniWidgets reports={businessPartnerReports} />
                                    </Row>
                                    <RevenueAnalytics />
                                </Col>
                                <Col xl={4}>
                                    <SalesAnalytics isAdmin={false} />
                                    <EarningReports />
                                </Col>
                            </Row>
                            <Row>
                                <LatestTransactions />
                                <RecentOrders isAdmin={false} />
                            </Row>
                        </>
                    )}
                </Container>
            </div>
        </>
    );
};

export default Dashboard;