import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader,
} from "../../store/actions";
import withRouter from "../Common/withRouter";

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pathName: this.props.router.location.pathname,
            localIsBusinessPartner: this.props.isBusinessPartner,
            localBusinessRole:this.props.hasLocalBusinessrole,
            permissions:this.props.haspermissions,
            
            
        };
    }

    componentDidMount() {
    
         this.fetchAndUpdateBusinessPartner();

        setTimeout(() => {
            this.initMenu();
        }, 10)
    }

   


    UNSAFE_componentDidUpdate(prevProps) {
        if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
            this.setState({ pathName: this.props.router.location.pathname }, () => {
                this.initMenu();
            });
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }
    


    initMenu() {
        const { pathName } = this.state;
        const menu = new MetisMenu("#side-menu");
    
        // Adding and removing the "mm-active" class to classname on clicking sidebar items.
        menu.off("click");
    
        // Add toggle functionality for parent menus
        menu.on("click", (event) => {
            const target = event.target;
    
            if (target.tagName === "A") {
                const parentItem = target.parentElement;
                
                if (parentItem && parentItem.classList.contains("has-arrow")) {
                    // Toggle mm-active and mm-show class for parent menus
                    parentItem.classList.toggle("mm-active");
                    const childUl = parentItem.querySelector("ul");
                    if (childUl) {
                        childUl.classList.toggle("mm-show");
                    }
                }
            }
        });
    
        // Activate the menu item that matches the current path
        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
    
        for (var i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
    
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }
    

    activateParentDropdown = (item) => {
        // Close any open parent menus
        const openItems = document.querySelectorAll("#side-menu li.mm-active");
        openItems.forEach((openItem) => {
            if (openItem !== item.parentElement) {
                openItem.classList.remove("mm-active");
                const openChildUl = openItem.querySelector("ul");
                if (openChildUl) {
                    openChildUl.classList.remove("mm-show");
                }
            }
        });
    
        // Activate the clicked item and its parents
        item.classList.add("mm-active");
    
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;
            if (parent2) {
                parent2.classList.add("mm-show");
    
                const parent3 = parent2.parentElement;
                if (parent3) {
                    parent3.classList.add("mm-active");
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
        }
    };
    

    fetchAndUpdateBusinessPartner = () => {
        const localData = localStorage.getItem("authUser");

        try {
            const dataString = JSON.parse(localData);
            this.setState({ localIsBusinessPartner: dataString.is_business_partner });
            this.setState({ localBusinessRole: dataString.role });
           // this.setState({ localBusinessRole:"Manager"});
            this.setState({permissions: dataString.permissions});
        } catch (e) {
            console.error("Error while fetching and updating local BusinessPartner", e);
        }
    };

    render() {
        const { isAuthenticated } = this.props;
        const { localIsBusinessPartner,localBusinessRole, permissions } = this.state;

        //console.log('local business ',localIsBusinessPartner,' authenticated ',isAuthenticated,'localBusinessRole ',localBusinessRole)
        // Utility function to normalize labels
            const normalizeLabel = (label) => {
                return label
                    .toLowerCase()
                    .replace(/\s+/g, ' ') // Ensure single space between words
                    .trim();
            };

            const sidebarItems = [
                {
                    parentCategory: "Store",
                    category: "Orders",
                    icon: <i className="ri-shopping-cart-2-line"></i>,
                    items: [
                        { path: "/route-money/waiter/pending-orders", label: "Pending Orders", icon: "ri-shopping-cart-2-line" },
                        { path: "/route-money/waiter/order-history", label: "Order History", icon: "ri-history-line" },
                    ]
                },
                {
                    parentCategory: "Store",
                    category: "Products",
                    icon: <i className="ri-shopping-cart-2-line"></i>,
                    items: [
                        { path: "/route-money/transactions/pending", label: "Categories", icon: "ri-file-list-line" },
                        { path: "/route-money/transactions/complete", label: "Sub-categories", icon: "ri-check-line" },
                        { path: "/route-money/transactions/complete", label: "Products", icon: "ri-check-line" },
                    ]
                },
                {
                    parentCategory: "Store",
                    category: "Offers",
                    icon: <i className="ri-shopping-cart-2-line"></i>,
                    items: [
                        { path: "/route-money/transactions/pending", label: "Product Discount", icon: "ri-file-list-line" },
                        { path: "/route-money/transactions/complete", label: "Cashback Offers", icon: "ri-check-line" },
                    ]
                },
                {
                    parentCategory: "Store",
                    category: "Tables",
                    label:"Tables",
                    icon: <i className="ri-shopping-cart-2-line"></i>,
                    items: [
                        { path: "/route-money/merchant/table/all", label: "Transactions", icon: "ri-coupon-line" }
                    ]
                    
                },
                {
                    category: "Transactions",
                    label:"Transactions",
                    icon: <i className="ri-wallet-3-line"></i>,
                    items: [
                        { path: "/route-money/merchant/transactions", label: "Transactions", icon: "ri-wallet-3-line" }
                    ]
                },
                {
                    category: "Withdraw",
                    label:"Withdraw",
                    icon: <i className="ri-money-dollar-circle-line"></i>,
                   items: [
                    { path: "/route-money/withdraw", label: "Withdraw", icon: "ri-money-dollar-circle-line" }
                ]
                },
                {
                    category: "Branch",
                    label:"Branch",
                    icon: <i className="ri-page-separator"></i>,
                    items: [
                        { path: "/route-money/merchant/branch/list", label: "Branch", icon: "ri-page-separatore" }
                    ]
                },
                {
                    category: "Users",
                    label:"Users",
                    icon: <i className="ri-group-line"></i>,
                 
                   items: [
                    { path: "/route-money/user", label: "Users", icon: "ri-group-line" }
                ]
                },
                {
                    category: "My Reports",
                    label:"My reports",
                    icon:  <i className="ri-bar-chart-line"></i>,
                   items: [
                    { path: "/route-money/merchant/revenue-report", label: "My reports", icon: "ri-bar-chart-line" }
                ]
                },
                {
                    category: "Activity log",
                   
                    icon:<i className="ri-git-branch-line"></i>,
                    items: [
                        { path: "/route-money/merchant/activities", label: "Activity Logs", icon: "ri-git-branch-line" }
                    ]
                },
                // Add more categories and items as needed
            ];
            
            // Add more categories and items as needed
        


   // Function to filter sidebar items based on permissions
const filterSidebarItems = (sidebarItems, permissions) => {
    const normalizedPermissions = Object.keys(permissions).reduce((acc, key) => {
        acc[normalizeLabel(key)] = permissions[key];
        return acc;
    }, {});

    return sidebarItems.map(category => {
        const filteredItems = category.items.filter(item => {
            const normalizedItemLabel = normalizeLabel(item.label);
            return normalizedPermissions[normalizedItemLabel];
        });

        if (filteredItems.length > 0) {
            return {
                category: category.category,
                items: filteredItems
            };
        }
        return null; // Exclude empty categories
    }).filter(category => category !== null); // Remove null categories
};
        
        const loginUseItems = filterSidebarItems(sidebarItems, permissions);

        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{this.props.t("Menu")}</li>

                        
               
                        {isAuthenticated && !localIsBusinessPartner && localBusinessRole ==="Admin" && (
                            <React.Fragment>
                                <li>
                                    <Link to="/route-money/dashboard" className="waves-effect mm-active">
                                        <i className="ri-dashboard-line"></i>
                                        <span className="badge rounded-pill bg-success float-end"></span>
                                        <span className="ms-1">{this.props.t("Dashboard")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-wallet-3-line"></i>
                                        <span className="ms-1">{this.props.t("Transactions")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/collection-transactions">
                                                {this.props.t("Collections")}
                                             </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/intrawallet-transactions">
                                                {this.props.t("Intrawallet")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/disbursement-history">
                                                {this.props.t("Disbursement")}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/route-money/withdraw" className="waves-effect">
                                    <i class="ri-money-dollar-circle-line"></i>
                                        <span className="ms-1">{this.props.t("Withdraw")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-shopping-cart-2-line"></i>
                                        <span className="ms-1">{this.props.t("Orders")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/pending-orders">
                                                {this.props.t("Pending Orders")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/order-history">{this.props.t("Order History")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-coupon-2-line"></i>
                                        <span className="ms-1">{this.props.t("Coupon Management")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/coupon-balance">
                                                {this.props.t("View Customer Coupon Balances")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/coupon-history">
                                                {this.props.t("History of Redeemed Coupons")}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <Link to="/route-money/revenue-report" className="waves-effect">
                                        <i className="ri-bar-chart-line"></i>
                                        <span className="ms-1">{this.props.t("Revenue Reports")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-user-line"></i>
                                        <span className="ms-1">{this.props.t("Users")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/pending-pos">
                                                {this.props.t("Pending POS Approvals")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/customers">{this.props.t("Customers")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/merchants">{this.props.t("Merchants")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/admin_staff">{this.props.t("Staff")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                {/* <li> 
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-group-line"></i>
                                        <span className="ms-1">{this.props.t("User Management")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/create-user">
                                                {this.props.t("Create User Role")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/user">{this.props.t("View and Manage Users")}</Link>
                                        </li>
                                    </ul>
                                </li> */}
                                <li>
                                    <Link to="/route-money/activities" className="waves-effect">
                                        <i className="ri-git-branch-line"></i>
                                        <span className="badge rounded-pill bg-success float-end"></span>
                                        <span className="ms-1">{this.props.t("Activity Logs")}</span>
                                    </Link>
                                </li>
                            </React.Fragment>
                        
                        )}

{isAuthenticated && !localIsBusinessPartner && localBusinessRole=="Waiter" || localBusinessRole=="waiter" && (

    <React.Fragment>
        <li>
            <Link to="/route-money/waiter/pending-orders" className="has-arrow waves-effect">
                <i className="ri-store-line"></i>
                <span className="ms-1">{this.props.t("Store")}</span>
            </Link>
            
           
            {loginUseItems.map((category, index) => (
                <>
                  <Link to="#" className="has-arrow waves-effect">
                  <i className="ri-wallet-3-line"></i>
                  <span className="ms-1">{category.category}</span>
              </Link>
                 <ul className="sub-menu">
                            {/* <li key={index}>
                              
                                <span className="ms-1">{category.category}</span>
                            </li> */}
                               
                                    {category.items.map((item, subIndex) => (
                                        <li key={subIndex}>
                                            <Link to={item.path} className={this.state.pathName === item.path ? "mm-active" : ""}>
                                                <i className={item.icon}></i>
                                                <span className="ms-1">{item.label}</span>
                                            </Link>
                                        </li>
                                    ))}
                                
                            
                             </ul>
                             </>
                        ))}
           
            
        </li>
    </React.Fragment>
)}

  {/* manager */}


                        {/* end manageable users */}

                        {/* branches sidebar */}
                  
                         {isAuthenticated && localIsBusinessPartner && localBusinessRole==='Manager' && (
                            <React.Fragment>
                                <li>
                                    <Link to="/route-money/branch/dashboard" className="waves-effect mm-active">
                                        <i className="ri-dashboard-line"></i>
                                        <span className="badge rounded-pill bg-success float-end"></span>
                                        <span className="ms-1">{this.props.t("Dashboard")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="" className="has-arrow waves-effect">
                                        <i className="ri-store-line"></i>
                                        <span className="ms-1">{this.props.t("Store")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="" className="has-arrow waves-effect">
                                                <i className="ri-shopping-cart-2-line"></i>
                                                <span className="ms-1">{this.props.t("Orders")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/branch/pending-orders">
                                                        {this.props.t("Pending Orders")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/branch/order-history">
                                                        {this.props.t("Order History")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="" className="has-arrow waves-effect">
                                                <i className="ri-shopping-bag-2-line"></i>
                                                <span className="ms-1">{this.props.t("Products")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/branch/product/categories">
                                                        {this.props.t("Categories")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/branch/product/sub-categories">
                                                        {this.props.t("Sub-Categories")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/branch/products">
                                                        {this.props.t("Products")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <Link to="" className="has-arrow waves-effect">
                                                <i className="ri-coupon-line"></i>
                                                <span className="ms-1">{this.props.t("Offers")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/branch/product-offers">
                                                        {this.props.t("Product Discount")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/branch/cashback-offers">
                                                        {this.props.t("Cashback Offers")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <Link to="/route-money/branch/table/all" className="waves-effect">
                                            <i className="ri-coupon-line"></i>
                                            <span className="ms-1">{this.props.t("Tables")}</span>
                                            </Link>
                                        </li>

                                    </ul>
                                </li>

                            
                                <li>
                                    <Link to="/route-money/branch/transactions" className="waves-effect">
                                        <i className="ri-wallet-3-line"></i>
                                        <span className="ms-1">{this.props.t("Transactions")}</span>
                                    </Link>
                                </li>

                             
                                {/* <li>
                                    <Link to="/route-money/branch/withdraw" className="waves-effect">
                                    <i class="ri-money-dollar-circle-line"></i>
                                        <span className="ms-1">{this.props.t("Withdraw")}</span>
                                    </Link>
                                </li>    */}
                                
                                <li>
                                    <Link to="/route-money/branch/user" className="waves-effect">
                                    <i className="ri-group-line"></i>
                                        <span className="ms-1">{this.props.t("Users")}</span>
                                    </Link>
                                </li> 

                                {/* 

                               <li>
                                    <Link to="/route-money/merchant/revenue-report" className="waves-effect">
                                    <i className="ri-bar-chart-line"></i>
                                    <span className="ms-1">{this.props.t("Branch Reports")}</span>
                                    </Link>
                                </li> */}

                                <li>
                                    <Link to="/route-money/merchant/activities" className="waves-effect">
                                        <i className="ri-git-branch-line"></i>
                                        <span className="badge rounded-pill bg-success float-end"></span>
                                        <span className="ms-1">{this.props.t("Activity Logs")}</span>
                                    </Link>
                                </li> 


                            </React.Fragment>
                        )} 


                        {/* try config manager sidebar */}


                        {/* end branches sidebar */}

                        {isAuthenticated && localIsBusinessPartner && localBusinessRole === "Merchant" &&(
                            <React.Fragment>
                                <li>
                                    <Link to="/route-money/dashboard" className="waves-effect mm-active">
                                        <i className="ri-dashboard-line"></i>
                                        <span className="badge rounded-pill bg-success float-end"></span>
                                        <span className="ms-1">{this.props.t("Dashboard")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-store-line"></i>
                                        <span className="ms-1">{this.props.t("Store")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/#" className="has-arrow waves-effect">
                                                <i className="ri-shopping-cart-2-line"></i>
                                                <span className="ms-1">{this.props.t("Orders")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/merchant/pending-orders">
                                                        {this.props.t("Pending Orders")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/merchant/order-history">
                                                        {this.props.t("Order History")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/#" className="has-arrow waves-effect">
                                                <i className="ri-shopping-bag-2-line"></i>
                                                <span className="ms-1">{this.props.t("Products")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/merchant/product/categories">
                                                        {this.props.t("Categories")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/merchant/product/sub-categories">
                                                        {this.props.t("Sub-Categories")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/merchant/products">
                                                        {this.props.t("Products")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <Link to="/#" className="has-arrow waves-effect">
                                                <i className="ri-coupon-line"></i>
                                                <span className="ms-1">{this.props.t("Offers")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/merchant/product-offers">
                                                        {this.props.t("Product Discount")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/merchant/cashback-offers">
                                                        {this.props.t("Cashback Offers")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        {/* create tables */}
                                        {/* <li>
                                            <Link to="/#" className="has-arrow waves-effect">
                                                <i className="ri-coupon-line"></i>
                                                <span className="ms-1">{this.props.t("Tables")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/merchant/table/add">
                                                        {this.props.t("Add Table")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/merchant/table/all">
                                                        {this.props.t("Tables")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li> */}
                                 <li>
                                    <Link to="/route-money/merchant/table/all" className="waves-effect">
                                    <i className="ri-coupon-line"></i>
                                    <span className="ms-1">{this.props.t("Tables")}</span>
                                    </Link>
                                </li>

                                    </ul>
                                </li>

                              

                                <li>
                                    <Link to="/route-money/merchant/transactions" className="waves-effect">
                                        <i className="ri-wallet-3-line"></i>
                                        <span className="ms-1">{this.props.t("Transactions")}</span>
                                    </Link>
                                </li>

                                
                                <li>
                                    <Link to="/route-money/withdraw" className="waves-effect">
                                    <i class="ri-money-dollar-circle-line"></i>
                                        <span className="ms-1">{this.props.t("Withdraw")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/route-money/merchant/branch/list" className="waves-effect">
                                        <i className="ri-page-separator"></i>
                                        <span className="ms-1">{this.props.t("Branch")}</span>
                                    </Link>
                                </li>

                                
                                <li>
                                    <Link to="/route-money/user" className="waves-effect">
                                    <i className="ri-group-line"></i>
                                        <span className="ms-1">{this.props.t("Users")}</span>
                                    </Link>
                                </li>
{/* 
                                <li> 
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="ri-group-line"></i>
                                    <span className="ms-1">{this.props.t("Users")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/route-money/create-user">
                                            {this.props.t("Create User Role")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/route-money/user">{this.props.t("View and Manage Users")}</Link>
                                    </li>
                                </ul>
                            </li> */}

                               <li>
                                    <Link to="/route-money/merchant/revenue-report" className="waves-effect">
                                    <i className="ri-bar-chart-line"></i>
                                    <span className="ms-1">{this.props.t("My Reports")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/route-money/merchant/activities" className="waves-effect">
                                        <i className="ri-git-branch-line"></i>
                                        <span className="badge rounded-pill bg-success float-end"></span>
                                        <span className="ms-1">{this.props.t("Activity Logs")}</span>
                                    </Link>
                                </li>

{/* 
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-bar-chart-line"></i>
                                        <span className="ms-1">{this.props.t("My Reports")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/merchant/revenue-report">
                                                {this.props.t("Revenue Report")}
                                            </Link>
                                        </li>
                                    </ul>
                                </li> */}
                            </React.Fragment>
                        )}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
        ...state.Layout,
        isAuthenticated: state.Login.isAuthenticated,
        isBusinessPartner: state.Login.isBusinessPartner,
        hasLocalBusinessrole: state.Login.hasLocalBusinessrole,
        haspermissions: state.Login.haspermissions,
        
    };
};

export default withRouter(
    connect(mapStatetoProps, {
        changeLayout,
        changeSidebarTheme,
        changeSidebarType,
        changeLayoutWidth,
        changePreloader,
    })(withTranslation()(SidebarContent))
);